import { observer } from "mobx-react";
import React, { Component } from "react";

import { getFormattedPhone, getPlainPhone } from "../../../helpers";
import { validateEmail } from "../../../utils/validators";

import BaseButton from "../../atoms/BaseButton";
import BaseInput from "../../atoms/BaseInput";
import BaseTabs from "../../atoms/BaseTabs";
import ModalContainer from "../../atoms/ModalContainer";
import PhoneInput from "../../atoms/PhoneInput";
import StrongPasswordLine from "../../atoms/StrongPasswordLine";

import elementsStore from "../../../store/elementsStore";
import ConfirmCodeInput from "../../atoms/ConfirmCodeInput/ConfirmCodeInput";
import s from "./RecoverModal.module.scss";

@observer
class RecoverModal extends Component {
	constructor(props) {
		super(props);
		this.intervalHandle = null;
	}

	state = {
		seconds: 0,
		sendPhoneForResetPassword: "",
		newPhoneForResetPassword: "",
		emailForResetPassword: "",
		inputPhoneLength: 0,
		recoveryTabs: { phoneNumber: "Номер телефона", email: "Email" },
		tabsActive: "phoneNumber",
		passwordNew: "",
		passwordNewConfirmation: "",
		invalidPasswordText: "",
		name: "",
	};

	componentDidUpdate(prevProps) {
		if (
			prevProps.recoverResult === null &&
			this.props.recoverResult &&
			this.props.recoverResult.result === "success"
		) {
			this.setState({
				seconds: this.props.recoverResult.seconds,
			});
			this.startCountDown();
		} else if (
			prevProps.recoverResult !== null &&
			prevProps.recoverResult.seconds !== this.props.recoverResult.seconds &&
			this.props.recoverResult.result === "success"
		) {
			this.setState({
				seconds: this.props.recoverResult.seconds,
			});
			this.startCountDown();
		}

		if (
			prevProps.recoverModalVisible === false &&
			this.props.recoverModalVisible === true
		) {
			this.setState({
				newPhoneForResetPassword: getPlainPhone(this.props.phoneRecover, {
					format: "withPlus",
				}),
			});
		}
	}

	componentWillUnmount() {
		clearInterval(this.intervalHandle);
	}

	tick = () => {
		if (this.state.seconds !== 0) {
			this.setState((prev) => {
				return {
					seconds: prev.seconds - 1,
				};
			});
		} else {
			this.setState({ seconds: 0 });
			clearInterval(this.intervalHandle);
		}
	};

	startCountDown() {
		this.intervalHandle = setInterval(this.tick, 1000);
	}

	isSamePhone() {
		return (
			this.state.newPhoneForResetPassword &&
			this.state.sendPhoneForResetPassword ===
				this.state.newPhoneForResetPassword
		);
	}

	onChangePhone = (x) => {
		const value = x;
		this.setState({
			inputPhoneLength: value.replace(/\D/g, "").length,
		});
		if (
			getPlainPhone(value, { format: "withPlus" }).match(
				/^\+7\d\d\d\d\d\d\d\d\d\d$/,
			)
		) {
			this.setState({
				newPhoneForResetPassword: getPlainPhone(value, { format: "withPlus" }),
			});
		}
		this.props.changeInput(x);
	};

	checkSmsCode = () => {
		this.props.recover("check_phone_code", {
			phone: this.state.sendPhoneForResetPassword,
			code: elementsStore.smsCode,
		});
	};

	onChangeEmail = (value) => {
		this.setState({
			emailForResetPassword: value,
		});
	};

	changePasswordField = (name, value) => {
		this.setState((_) => ({ [name]: value }));
	};

	sendConfirmCode = () => {
		this.props.recover("send_phone_code", {
			phone: this.state.sendPhoneForResetPassword,
		});
	};

	prevStep = () => {
		this.props.prevStepRecoverModal();
	};

	disableButton = () => {
		if (this.state.tabsActive === "phoneNumber") {
			return (
				!this.state.newPhoneForResetPassword.match(
					/^\+7\d\d\d\d\d\d\d\d\d\d$/,
				) ||
				(this.state.seconds !== 0 && !this.state.newPhoneForResetPassword) ||
				(this.isSamePhone() && this.state.seconds !== 0) ||
				this.state.inputPhoneLength !== 11
			);
		}

		if (this.state.tabsActive === "email") {
			return (
				!this.state.emailForResetPassword.length ||
				!validateEmail(this.state.emailForResetPassword)
			);
		}
	};

	onSendFirstStep = (_) => {
		if (this.state.name !== "") return;

		if (this.state.tabsActive === "phoneNumber") {
			this.setState({
				sendPhoneForResetPassword: this.props.phoneRecover,
				newPhoneForResetPassword: "",
			});
			clearInterval(this.intervalHandle);
			this.props.recover("send_phone_code", {
				phone: this.state.newPhoneForResetPassword,
			});
		}
		if (this.state.tabsActive === "email") {
			this.props.recover("restore_with_mail", {
				email: this.state.emailForResetPassword,
			});
		}
	};

	createPassword = () => {
		if (this.state.passwordNewConfirmation !== this.state.passwordNew) {
			this.setState(() => ({ invalidPasswordText: "Пароли не совпадают" }));
			return;
		}
		this.setState(() => ({ invalidPasswordText: "" }));

		if (this.state.sendPhoneForResetPassword) {
			this.props.recover("restore_with_code", {
				phone: this.state.sendPhoneForResetPassword,
				code: elementsStore.smsCode,
				newPassword: this.state.passwordNew,
			});
		} else {
			this.props.recover("change_password_link", {
				newPassword: this.state.passwordNew,
			});
		}
	};

	render() {
		const {
			recoverResult,
			hideRecoverModal,
			recoverModalVisible,
			recoverModalStep,
			loading,
			phoneRecover,
		} = this.props;

		const { recoveryTabs, tabsActive, passwordNew, passwordNewConfirmation } =
			this.state;

		const stepOne = (
			<>
				<div className={s.modal}>
					<p>
						Нам нужно убедиться, что это действительно Вы. Мы отправим{" "}
						{tabsActive === "phoneNumber"
							? "код подтверждения в SMS"
							: "письмо с ссылкой для сброса пароля"}
					</p>
					<div className={s.modal__tabs}>
						<BaseTabs
							list={recoveryTabs}
							active={tabsActive}
							change={(value) => this.setState({ tabsActive: value })}
							dividerColor={"gray"}
						/>
					</div>
					{tabsActive === "phoneNumber" ? (
						<div>
							<div className={s.modal__input}>
								<PhoneInput
									type="number"
									placeholder="Номер телефона"
									value={(x) => this.onChangePhone(x)}
									defaultValue={phoneRecover}
								/>
								{recoverResult?.result === "error" && (
									<div
										className={s.modal__errorMessage}
										// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
										dangerouslySetInnerHTML={{ __html: recoverResult?.text }}
									/>
								)}
							</div>
							<div className={s.name}>
								<label htmlFor="name">Имя</label>
								<input
									onChange={(e) => this.setState({ name: e.target.value })}
									value={this.state.name}
									type="text"
									id="name"
									name="name"
								/>
							</div>
						</div>
					) : (
						<div>
							<div className={s.modal__input}>
								<BaseInput
									type="email"
									placeholder="Email"
									value={(value) => this.onChangeEmail(value)}
								/>
								{recoverResult?.result === "error" && (
									<div
										className={s.modal__errorMessage}
										// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
										dangerouslySetInnerHTML={{ __html: recoverResult?.text }}
									/>
								)}
							</div>
						</div>
					)}
					<div className={s.modal__btn}>
						<BaseButton
							text="Продолжить"
							type="green"
							size="big"
							loading={loading}
							disabled={this.disableButton()}
							onClick={this.onSendFirstStep}
						/>
					</div>
				</div>
			</>
		);

		const stepTwo = (
			<>
				<div className={s.modal}>
					<p>
						Мы отправили код доступа на номер{" "}
						<span style={{ whiteSpace: "nowrap" }}>
							{getFormattedPhone(phoneRecover)}
						</span>{" "}
						<br />
						Введите код из SMS:
					</p>
					<div className={s.modal__input}>
						<ConfirmCodeInput
							onEntered={this.checkSmsCode}
							onSend={this.sendConfirmCode}
						/>
					</div>
				</div>
			</>
		);

		const stepTwoEmail = (
			<>
				<div className={s.modal}>
					<p>
						На вашу почту {this.state.emailForResetPassword} было отправлено
						письмо для восстановления.
					</p>
				</div>
			</>
		);

		const stepThree = (
			<>
				<div className={s.modal}>
					<p>Установите новый пароль для Личного кабинета</p>
					<div>
						<div>
							<div className={s.modal__input}>
								<BaseInput
									type="password"
									placeholder="Придумайте новый пароль"
									value={(x) => this.changePasswordField("passwordNew", x)}
								/>
							</div>
							<div className={s.modal__input}>
								<StrongPasswordLine value={passwordNew} />
							</div>
							<div className={s.modal__input}>
								<BaseInput
									type="password"
									placeholder="Повторите пароль"
									value={(x) =>
										this.changePasswordField("passwordNewConfirmation", x)
									}
								/>
							</div>
						</div>
						{this.state.invalidPasswordText && (
							<div className={s.modal__errorMessage}>
								{this.state.invalidPasswordText}
							</div>
						)}
						<div className={s.modal__btn}>
							<BaseButton
								text="Продолжить"
								type="green"
								size="big"
								loading={loading}
								onClick={this.createPassword}
								disabled={
									!passwordNew ||
									!passwordNewConfirmation ||
									passwordNew.length < 6 ||
									!/[^0-9]+/g.test(passwordNew) ||
									!/\d/g.test(passwordNew)
								}
							/>
						</div>
					</div>
				</div>
			</>
		);

		const stepFour = (
			<>
				<div className={s.modal}>
					<p>
						Вы успешно восстановили пароль! <br />
						<br />
						Через несколько секунд будет выполнен автоматический переход в Ваш
						Личный кабинет.
					</p>
				</div>
			</>
		);

		return (
			<React.Fragment>
				<ModalContainer
					showModal={recoverModalVisible}
					closeModal={() => hideRecoverModal()}
					title={
						recoverModalStep === 4 ? "Поздравляю 🎉" : "Восстановление пароля"
					}
					btnBack={recoverModalStep === 2 && tabsActive === "phoneNumber"}
					onClickBack={this.prevStep}
				>
					{recoverModalStep === 1 && stepOne}
					{recoverModalStep === 2 && tabsActive === "phoneNumber" && stepTwo}
					{recoverModalStep === 2 && tabsActive === "email" && stepTwoEmail}
					{recoverModalStep === 3 && stepThree}
					{recoverModalStep === 4 && stepFour}
				</ModalContainer>
			</React.Fragment>
		);
	}
}

export default RecoverModal;
